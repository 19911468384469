import { GET_OFFER, SET_OFFERS_LOADING, GET_OFFERS } from '../actions/types';

const initialState = {
  offer: {},
  offers: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OFFERS_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_OFFER:
      return {
        ...state,
        offer: action.payload,
        loading: false
      };
    case GET_OFFERS:
      return {
        ...state,
        offers: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
