import React, { Suspense, Component } from "react";
import { Switch, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import axios from "axios";

import { ToastContainer } from "react-toastify";

// Imports from Redux
import store from "./store";
import setAuthToken from "./app/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./app/actions/authActions";

import "./App.scss";

// Importing Modules
import Loader from "./app/modules/Loader/index";
import PrivateRoute from "./app/common/PrivateRoute";
import ApproveRejectOffer from "./app/modules/ApproveReject/Offer";
import ApproveRejectDemandLetter from "./app/modules/ApproveReject/DemandLetter";
import EnterEmailForgotPassword from "./app/modules/ForgotPassword/EnterEmail";
import EnterNewPassword from "./app/modules/ForgotPassword/EnterNewPassword";
const Login = React.lazy(() => import("./app/modules/Login/index"));
const Homepage = React.lazy(() => import("./app/layout/DefaultLayout"));

axios.defaults.baseURL = "http://localhost:5500/api/v1";
if (process.env.REACT_APP_ENV === "qa") {
  axios.defaults.baseURL = "/api/v1";
}
let decoded = null;
//Check for token
if (localStorage.legalToken) {
  // Set headers
  setAuthToken(localStorage.legalToken);
  // Get user info and exp
  decoded = jwt_decode(localStorage.legalToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // logout User
    store.dispatch(logoutUser());
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <ToastContainer />
          <Switch>
            <Route
              path="/accept-reject-offer/:token"
              render={(props) => {
                return <ApproveRejectOffer {...props} />;
              }}
            />
            <Route
              path="/accept-reject-demand-letter/:token"
              render={(props) => {
                return <ApproveRejectDemandLetter {...props} />;
              }}
            />
            <Route path="/reset-password/:token" component={EnterNewPassword} />
            <Route path="/enter-email" component={EnterEmailForgotPassword} />
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" component={Homepage} />
          </Switch>
        </Suspense>
      </Provider>
    );
  }
}

export default App;
