import {
  SET_CORPORATE_ENTITY_LOADING,
  GET_CORPORATE_ENTITY,
  GET_CORPORATE_ENTITIES,
  GET_ENTITIES_FOR_SELECT_NAME,
  GET_ENTITIES_FOR_SELECT,
} from "../actions/types";

const initialState = {
  entity: {},
  entities: [],
  dataForSelect: {},
  loading: false,
};

const corporateEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CORPORATE_ENTITY_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_CORPORATE_ENTITY:
      return {
        ...state,
        entity: action.payload,
        loading: false,
      };
    case GET_CORPORATE_ENTITIES:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case GET_ENTITIES_FOR_SELECT_NAME:
      return {
        ...state,
        dataForSelect: {
          ...state.dataForSelect,
          corpNames: action.payload,
        },
      };
    case GET_ENTITIES_FOR_SELECT:
      return {
        ...state,
        dataForSelect: {
          ...state.dataForSelect,
          corpEntities: action.payload,
        },
      };
    default:
      return state;
  }
};

export default corporateEntityReducer;
