// Auth
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// Errors
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Notifications
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const ADD_NOTIFICATION_REAL_TIME = "ADD_NOTIFICATION_REAL_TIME";
export const MARK_NOTIFICATION = "MARK_NOTIFICATION";

// Cases
export const GET_CASES = "GET_CASES";
export const GET_CASE = "GET_CASE";
export const CASE_LOADING = "CASE_LOADING";
export const ADD_EXISTING_PARTY_TO_CASE = "ADD_EXISTING_PARTY_TO_CASE";
export const ADD_EXISTING_COUNSEL_TO_CASE = "ADD_EXISTING_COUNSEL_TO_CASE";
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const GET_CASES_DATA_FOR_SELECT = "GET_CASES_DATA_FOR_SELECT";
export const GET_PLAINTIFFS_AND_DEFENDANTS = "GET_PLAINTIFFS_AND_DEFENDANTS";
export const DELETE_CASE = "DELETE_CASE";
export const GET_CASES_FOR_ALL_TASKS = "GET_CASES_FOR_ALL_TASKS";
export const GET_CASES_FOR_ALL_PAST_TASKS = "GET_CASES_FOR_ALL_PAST_TASKS";
export const GET_DEFENDANTS = "GET_DEFENDANTS";

// Parties
export const GET_ALL_PARTIES = "GET_ALL_PARTIES";
export const GET_ALL_PARTIES_NOT_IN_CASE = "GET_ALL_PARTIES_NOT_IN_CASE";
export const GET_PARTY = "GET_PARTY";
export const ADD_PARTY = "ADD_PARTY";
export const UPDATE_PARTY = "UPDATE_PARTY";
export const DELETE_PARTY = "DELETE_PARTY";
export const ADD_PARTY_TO_CASEE = "ADD_PARTY_TO_CASEE";
export const UPDATE_PARTY_FROM_CASEE = "UPDATE_PARTY_FROM_CASEE";
export const DELETE_PARTY_FROM_CASEE = "DELETE_PARTY_FROM_CASEE";
export const PARTY_LOADING = "PARTY_LOADING";
export const ADD_PARTY_IN_PARTIES = "ADD_PARTY_IN_PARTIES";
export const GET_PARTIES_FOR_FILTERS = "GET_PARTIES_FOR_FILTERS";

// Counsels
export const GET_ALL_COUNSELS = "GET_ALL_COUNSELS";
export const GET_ALL_COUNSELS_NOT_IN_CASE = "GET_ALL_COUNSELS_NOT_IN_CASE";
export const GET_COUNSEL = "GET_COUNSEL";
export const ADD_COUNSEL = "ADD_COUNSEL";
export const UPDATE_COUNSEL = "UPDATE_COUNSEL";
export const DELETE_COUNSEL = "DELETE_COUNSEL";
export const ADD_COUNSEL_TO_CASEE = "ADD_COUNSEL_TO_CASEE";
export const UPDATE_COUNSEL_FROM_CASEE = "UPDATE_COUNSEL_FROM_CASEE";
export const DELETE_COUNSEL_FROM_CASEE = "DELETE_COUNSEL_FROM_CASEE";
export const COUNSEL_LOADING = "COUNSEL_LOADING";
export const GET_COUNSELS_FOR_FILTERS = "GET_COUNSELS_FOR_FILTERS";

// Events
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_WITH_EVENTS = "GET_LOCATIONS_WITH_EVENTS";
export const GET_LOCATIONS_WITH_PAST_EVENTS = "GET_LOCATIONS_WITH_PAST_EVENTS";
export const CREATE_EVENT_FROM_CASE = "CREATE_EVENT_FROM_CASE";
export const CREATE_EVENT = "CREATE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_EVENT = "GET_EVENT";
export const EVENTS_LOADING = "EVENTS_LOADING";
export const ADD_LOCATION_FROM_EVENT = "ADD_LOCATION_FROM_EVENT";
export const ADD_CLIENT_TO_EVENT = "ADD_CLIENT_TO_EVENT";
export const INVITE_PARTY_TO_EVENT = "INVITE_PARTY_TO_EVENT";
export const GET_CURRENT_CLIENTS = "GET_CURRENT_CLIENTS";
export const GET_ALL_USERS_FOR_EVENTS = "GET_ALL_USERS_FOR_EVENTS";
export const GET_PARTIES_FOR_EVENT = "GET_PARTIES_FOR_EVENT";
export const GET_ALL_PAST_EVENTS = "GET_ALL_PAST_EVENTS";

// Statuses
export const GET_STATUS = "GET_STATUS";
export const UPDATE_STATUS_IDS = "UPDATE_STATUS_IDS";
export const UPDATE_STATUS_IDS_LOCAL = "UPDATE_STATUS_IDS_LOCAL";
export const ADD_NEW_STEP_TO_STATUS = "ADD_NEW_STEP_TO_STATUS";
export const UPDATE_STEP_IN_STATUS = "UPDATE_STEP_IN_STATUS";
export const DELETE_STEP_IN_STATUS = "DELETE_STEP_IN_STATUS";

// Tasks
export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const GET_TASK = "GET_TASK";
export const CREATE_TASK = "CREATE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const ADD_CLIENT_TO_TASK = "ADD_CLIENT_TO_TASK";
export const ADD_ALL_CLIENTS_TO_TASK = "ADD_ALL_CLIENTS_TO_TASK";
export const GET_CURRENT_TASK_CLIENTS = "GET_CURRENT_TASK_CLIENTS";
export const GET_ALL_USERS_FOR_TASKS = "GET_ALL_USERS_FOR_TASKS";
export const TASKS_LOADING = "TASKS_LOADING";

//Activity
export const GET_ALL_ACTIVITY = "GET_ALL_ACTIVITY";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITIES_WITH_EXPENSES = "GET_ACTIVITIES_WITH_EXPENSES";
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const ACTIVITY_LOADING = "ACTIVITY_LOADING";

//Expenses
export const GET_ALL_EXPENSES = "GET_ALL_EXPENSES";
export const GET_EXPENSE = "GET_EXPENSE";
export const CREATE_EXPENSES = "CREATE_EXPENSES";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const EXPENSES_LOADING = "EXPENSES_LOADING";

//Properties
export const GET_ALL_PROPERTIES = "GET_ALL_PROPERTIES";
export const GET_PROPERTY = "GET_PROPERTY";
export const CREATE_PROPERTY = "CREATE_PROPERTY";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const PROPERTY_LOADING = "PROPERTY_LOADING";

//User
export const GET_USER = "GET_USER";
export const GET_ALL_USERS_FOR_TABLE_ATTENDS =
  "GET_ALL_USERS_FOR_TABLE_ATTENDS";
export const GET_ALL_USERS_ASSIGNED_TO_EXPENSES =
  "GET_ALL_USERS_ASSIGNED_TO_EXPENSES";
export const REMOVE_USER_FROM_USERS_FOR_TABLE_ATTENDS =
  "REMOVE_USER_FROM_USERS_FOR_TABLE_ATTENDS";
export const GET_USERS_TASK_ARE_ASSIGNED_TO = "GET_USERS_TASK_ARE_ASSIGNED_TO";
export const GET_USERS_TRANSACTIONAL_TASK_ARE_ASSIGNED_TO =
  "GET_USERS_TRANSACTIONAL_TASK_ARE_ASSIGNED_TO";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const GET_ALL_USERS_FOR_SELECT = "GET_ALL_USERS_FOR_SELECT";

//Transactional Task
export const SET_TRANSACTIONAL_TASK_LOADING = "SET_TRANSACTIONAL_TASK_LOADING";
export const GET_TRANSACTIONAL_TASK = "GET_TRANSACTIONAL_TASK";
export const GET_ALL_TRANSACTIONAL_TASKS = "GET_ALL_TRANSACTIONAL_TASKS";
export const ADD_CLIENT_TO_TRANSACTIONAL_TASK =
  "ADD_CLIENT_TO_TRANSACTIONAL_TASK";
export const GET_CURRENT_TRANSACTIONAL_TASK_CLIENTS =
  "GET_CURRENT_TRANSACTIONAL_TASK_CLIENTS";
export const GET_ALL_USERS_FOR_TRANSACTIONAL_TASKS =
  "GET_ALL_USERS_FOR_TRANSACTIONAL_TASKS";
export const RESET_USER = "RESET_USER";
export const ADD_ALL_CLIENTS_TO_TRANSACTIONAL_TASK =
  "ADD_ALL_CLIENTS_TO_TRANSACTIONAL_TASK";

//Corporate Entity
export const SET_CORPORATE_ENTITY_LOADING = "SET_CORPORATE_ENTITY_LOADING";
export const GET_CORPORATE_ENTITY = "GET_CORPORATE_ENTITY";
export const GET_CORPORATE_ENTITIES = "GET_CORPORATE_ENTITIES";
export const GET_ENTITIES_FOR_SELECT_NAME = "GET_ENTITIES_FOR_SELECT_NAME";
export const GET_ENTITIES_FOR_SELECT = "GET_ENTITIES_FOR_SELECT";

//Task Names Actions
export const SET_TASK_NAMES_LOADING = "SET_TASK_NAMES_LOADING";
export const GET_TASK_NAME = "GET_TASK_NAME";
export const GET_TASK_NAMES = "GET_TASK_NAMES";
export const ADD_CLIENT_TO_TASK_NAME = "ADD_CLIENT_TO_TASK_NAME";
export const GET_CURRENT_TASK_NAME_CLIENTS = "GET_CURRENT_TASK_NAME_CLIENTS";
export const GET_ALL_USERS_FOR_TASK_NAMES = "GET_ALL_USERS_FOR_TASK_NAMES";
export const ADD_ALL_CLIENTS_TO_TASK_NAME = "ADD_ALL_CLIENTS_TO_TASK_NAME";

// Roles
export const CREATE_NEW_ROLE = "CREATE_NEW_ROLE";
export const GET_ROLE = "GET_ROLE";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const RESET_ROLE = "RESET_ROLE";

// Support Tables
export const GET_SUPPORT_TABLES = "GET_SUPPORT_TABLES";
export const GET_SUPPORT_TABLE = "GET_SUPPORT_TABLE";
export const POST_SUPPORT_TABLE = "POST_SUPPORT_TABLE";
export const PATCH_SUPPORT_TABLE = "PATCH_SUPPORT_TABLE";
export const RESET_SUPPORT_TABLE = "RESET_SUPPORT_TABLE";

// Demand Letters
export const GET_FILES = "GET_FILES";
export const SET_DEMAND_LETTER_LOADING = "SET_DEMAND_LETTER_LOADING";
export const GET_DEMAND_LETTER = "GET_DEMAND_LETTER";
export const GET_ALL_DEMAND_LETTERS = "GET_ALL_DEMAND_LETTERS";
export const GET_PREVIEW_DEMAND_LETTER = "GET_PREVIEW_DEMAND_LETTER";
export const GET_CLIENT_RESPONSE = "GET_CLIENT_RESPONSE";
export const GET_DEMAND_LETTER_TEMPLATE = "GET_DEMAND_LETTER_TEMPLATE";
export const CLEAR_DEMAND_LETTER = "CLEAR_DEMAND_LETTER";
export const SIGN_DEMAND_LETTER = "SIGN_DEMAND_LETTER";

// Offers
export const GET_OFFER = "GET_OFFER";
export const GET_OFFERS = "GET_OFFERS";
export const SET_OFFERS_LOADING = "SET_OFFERS_LOADING";

// Lease Abstract Actions
export const GET_ALL_LEASE_ABSTRACT = "GET_ALL_LEASE_ABSTRACT";
export const GET_ALL_LEASE_ABSTRACT_SELECT = "GET_ALL_LEASE_ABSTRACT_SELECT";
export const GET_LEASE_ABSTRACT = "GET_LEASE_ABSTRACT";
export const CREATE_LEASE_ABSTRACT = "CREATE_LEASE_ABSTRACT";
export const UPDATE_LEASE_ABSTRACT = "UPDATE_LEASE_ABSTRACT";
export const DELETE_LEASE_ABSTRACT = "DELETE_LEASE_ABSTRACT";
export const LEASE_ABSTRACT_LOADING = "LEASE_ABSTRACT_LOADING";

// Bankryptcy Types
export const GET_BANKRUPTCY = "GET_BANKRUPTCY";
export const BANKRUPTCY_LOADING = "BANKRUPTCY_LOADING";
