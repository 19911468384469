const entitiesPermissions = [
  { value: 0, label: "Can View Entity" },
  { value: 1, label: "Can View Entity Table" },
  { value: 2, label: "Can Add Entity" },
];

const miscExpensesPermissions = [];

export default [
  {
    value: "Entities",
    label: "Entities",
    permissions: entitiesPermissions,
  },
  {
    value: "Entity Expenses",
    label: "Entity Expenses",
    permissions: entitiesPermissions,
  },
  {
    value: "Misc. Expenses",
    label: "Misc. Expenses",
    permissions: miscExpensesPermissions,
  },
];
