import {
  GET_ALL_USERS_ASSIGNED_TO_EXPENSES,
  GET_USERS_TASK_ARE_ASSIGNED_TO,
  GET_USERS_TRANSACTIONAL_TASK_ARE_ASSIGNED_TO,
  GET_ALL_USERS,
  GET_USER,
  RESET_USER,
  GET_ALL_USERS_FOR_SELECT
} from '../actions/types';

const initialState = {
  users: [],
  usersForSelect: [],
  user: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS_ASSIGNED_TO_EXPENSES:
      return {
        ...state,
        users: action.payload
      };
    case GET_USERS_TASK_ARE_ASSIGNED_TO:
      return {
        ...state,
        users: action.payload
      };
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload
      };
    case GET_ALL_USERS_FOR_SELECT:
      return {
        ...state,
        usersForSelect: action.payload
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload
      };
    case RESET_USER:
      return {
        ...state,
        user: action.payload
      };
    case GET_USERS_TRANSACTIONAL_TASK_ARE_ASSIGNED_TO:
      return {
        ...state,
        users: action.payload
      };
    default:
      return state;
  }
}
