import {
  GET_ENTITY_EXPENSE,
  SET_ENTITY_EXPENSES_LOADING,
  GET_ALL_ENTITY_EXPENSES,
  SET_SELECT_CORPORATE_ENTITIES,
  SET_SELECT_CORPORATE_EXPENSES,
  SET_SELECT_USERS,
} from "./types";

const initialState = {
  entityExpense: {},
  entityExpenses: [],
  selectOptions: {
    corporateEntities: [],
    corporateExpenses: [],
    users: [],
  },
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ENTITY_EXPENSES_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_ENTITY_EXPENSE:
      return {
        ...state,
        entityExpense: action.payload,
        loading: false,
      };
    case GET_ALL_ENTITY_EXPENSES:
      return {
        ...state,
        entityExpenses: action.payload,
        loading: false,
      };
    case SET_SELECT_CORPORATE_ENTITIES:
      return {
        ...state,
        selectOptions: {
          ...state.selectOptions,
          corporateEntities: action.payload,
        },
      };
    case SET_SELECT_CORPORATE_EXPENSES:
      return {
        ...state,
        selectOptions: {
          ...state.selectOptions,
          corporateExpenses: action.payload,
        },
      };
    case SET_SELECT_USERS:
      return {
        ...state,
        selectOptions: {
          ...state.selectOptions,
          users: action.payload,
        },
      };
    default:
      return state;
  }
};
