import React, { Component } from "react";
import Loader from "react-loader-spinner";

import "./index.css";

export default class index extends Component {
  render() {
    return (
      <div className={this.props.full ? "loaderBackground" : ""}>
        <div className={"loader"}>
          <Loader type="Grid" color="#258DB7" height={80} width={80} />
        </div>
      </div>
    );
  }
}
