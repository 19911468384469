import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Cryptr from "cryptr";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";

import { getUserFromNewCRM } from "../actions/newCrmActions";
import { getUser } from "../actions/userActions";
import {
  loginUser,
  loginUserWhenComesFromNewCRM,
} from "../actions/authActions";

function PrivateRoute({
  component: Component,
  auth,
  loginUser,
  getUser,
  getUserFromNewCRM,
  loginUserWhenComesFromNewCRM,
  ...rest
}) {
  let currentLocation = rest.location.pathname + rest.location.search;

  useEffect(() => {
    const urlFromCRM = queryString.parse(rest.location.search);
    if (urlFromCRM.fromCRM || urlFromCRM.fromNewCRM) {
      loginUserFromCRM(urlFromCRM);
    }
  }, []);

  const loginUserFromCRM = async (urlFromCRM) => {
    if (urlFromCRM.fromNewCRM) {
      const cryptr = new Cryptr("legal-password");
      const stringToDecrypt = urlFromCRM.fromNewCRM;
      const decriptedMessage = cryptr.decrypt(stringToDecrypt);
      const decriptedData = JSON.parse(decriptedMessage);
      // console.log("[DECRIPTED DATA] => ", decriptedData);
      const {
        data: { user },
      } = await getUserFromNewCRM(decriptedData.plaintiff);
      if (user) {
        await loginUserWhenComesFromNewCRM({
          email: user.email,
          name: user.name,
        });
      }
    } else {
      const cryptr = new Cryptr("legal-password");
      const stringToDecrypt = urlFromCRM.fromCRM;
      const decriptedMessage = cryptr.decrypt(stringToDecrypt);
      const data = JSON.parse(decriptedMessage);
      const user = await getUser(data.plaintiff);
      if (user) {
        await loginUser({
          email: user.email,
          password: user.password,
          isEncrypted: true,
        });
      }
    }
  };

  if (auth.isAuthenticated === false) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            fromPathname: currentLocation,
          },
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          return auth.isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          );
        }}
      />
    );
  }
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  loginUser,
  getUser,
  getUserFromNewCRM,
  loginUserWhenComesFromNewCRM,
})(PrivateRoute);
