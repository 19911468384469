import {
  CREATE_ACTIVITY,
  ACTIVITY_LOADING,
  GET_ALL_ACTIVITY,
  GET_ACTIVITY,
  GET_ACTIVITIES_WITH_EXPENSES
} from '../actions/types';

const initialState = {
  activities: [],
  activity: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
        loading: false
      };
    case GET_ACTIVITIES_WITH_EXPENSES:
      return {
        ...state,
        activities: action.payload
      };
    case ACTIVITY_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case CREATE_ACTIVITY:
      return {
        ...state,
        activities: [...state.activities, action.payload],
        loading: false
      };
    case GET_ALL_ACTIVITY:
      return {
        ...state,
        activities: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
