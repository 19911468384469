import {
  UPDATE_PARTY,
  DELETE_PARTY,
  GET_ALL_PARTIES,
  GET_PARTY,
  PARTY_LOADING,
  GET_ALL_PARTIES_NOT_IN_CASE,
  ADD_PARTY_IN_PARTIES,
  GET_PARTIES_FOR_FILTERS
} from '../actions/types';

const initialState = {
  parties: [],
  partiesNotInCase: [],
  partiesForFilters: [],
  party: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PARTY_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_PARTIES_FOR_FILTERS:
      return {
        ...state,
        partiesForFilters: action.payload
      };
    case GET_ALL_PARTIES:
      return {
        ...state,
        parties: action.payload,
        loading: false
      };
    case ADD_PARTY_IN_PARTIES:
      return {
        ...state,
        parties: [...state.parties, action.payload],
        loading: false
      };
    case GET_ALL_PARTIES_NOT_IN_CASE:
      return {
        ...state,
        partiesNotInCase: action.payload,
        loading: false
      };
    case GET_PARTY:
      return {
        ...state,
        party: action.payload,
        loading: false
      };
    case UPDATE_PARTY:
      return {
        ...state,
        party: action.payload,
        loading: false
      };
    case DELETE_PARTY:
      return {
        ...state,
        party: {}
      };
    default:
      return state;
  }
}
