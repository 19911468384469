import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import notificationReducer from "./notificationReducer";
import caseReducer from "./caseReducer";
import partiesReducer from "./partiesReducer";
import counselsReducer from "./counselsReducer";
import eventsReducer from "./eventsReducer";
import statusesReducer from "./statusesReducers";
import tasksReducer from "./tasksReducer";
import activityReducer from "./activityReducer";
import expensesReducer from "./expensesReducer";
import propertiesReducer from "./propertiesReducer";
import transactionalTasksReducer from "./transactionalTaskReducer";
import userReducer from "./userReducer";
import corporateEntityReducer from "./corporateEntityReducer";
import taskNamesReducer from "./taskNamesReducer";
import rolesReducer from "./rolesReducer";
import supportTablesReducer from "./supportTablesReducer";
import demandLettersReducer from "./demandLettersReducer";
import offersReducer from "./offersReducer";
import leaseAbstractReducer from "./leaseAbstractReducer";
import paymentPlanReducer from "../modules/SettlementAgreementAndPaymentPlan/PaymentPlan/redux/reducer";
import partialPaymentReducer from "../modules/PartialPayments/redux/reducer";
import bankruptcyReducer from "./bankruptcyReducer";
import miscExpenseReducer from "../modules/MiscExpenses/redux/reducer";
import invoiceReducer from "../modules/Invoices/redux/reducer";
import entityExpenseReducer from "../modules/CorporateExpenses/redux/reducer";

const rootReducer = combineReducers({
  errors: errorReducer,
  auth: authReducer,
  notifications: notificationReducer,
  cases: caseReducer,
  parties: partiesReducer,
  counsels: counselsReducer,
  events: eventsReducer,
  statuses: statusesReducer,
  tasks: tasksReducer,
  activity: activityReducer,
  expenses: expensesReducer,
  properties: propertiesReducer,
  users: userReducer,
  transactionalTasks: transactionalTasksReducer,
  corporateEntity: corporateEntityReducer,
  taskNames: taskNamesReducer,
  roles: rolesReducer,
  supportTables: supportTablesReducer,
  demandLetters: demandLettersReducer,
  offers: offersReducer,
  leaseAbstract: leaseAbstractReducer,
  paymentPlan: paymentPlanReducer,
  bankruptcy: bankruptcyReducer,
  miscExpenses: miscExpenseReducer,
  invoices: invoiceReducer,
  entityExpenses: entityExpenseReducer,
  partialPayment: partialPaymentReducer,
});

export default rootReducer;
