import axios from "axios";
import { notificationDanger } from "../utils/customToastify";

export const getUserFromNewCRM = (userId) => async (_dispatch) => {
  try {
    const response = await axios.get(`/new-crm/users/${userId}`);
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    notificationDanger("Error, something went wrong on CRM server.");
  }
};

export const getCrmAPIData = (options) => async (_dispatch) => {
  try {
    const response = await axios.get(`/new-crm-protected/contact/legal-data`, {
      params: { options },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notificationDanger("Error, something went wrong on CRM server.");
  }
};
