import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { SET_CURRENT_USER, GET_ERRORS } from "./types";
import { clearErrors } from "./errorsActions";
import { notificationDanger } from "../utils/customToastify";

export const loginUser = (userData, history) => (dispatch) => {
  return axios
    .post("/auth/login-user", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("legalToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const loginUserWhenComesFromNewCRM = (userData) => async (dispatch) => {
  try {
    const response = await axios.post(
      "/auth/login-user-from-new-crm",
      userData
    );
    if (response.status === 200) {
      const { token } = response.data;
      localStorage.setItem("legalToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      dispatch(clearErrors());
    }
  } catch (err) {
    if (err.response.status === 401) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    } else notificationDanger("Server error, something went wrong");
  }
};

// Set loged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Logout user
export const logoutUser = (fromCps) => (dispatch) => {
  localStorage.removeItem("legalToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  if (!fromCps) {
    window.location.href = "/login";
  }
};
