import { LOADING, GET_ALL_INVOICES, GET_INVOICE } from "./types";

const initialState = {
  invoice: {},
  invoices: {
    data: [],
    query: {}
  },
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_ALL_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        loading: false
      };
    case GET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
