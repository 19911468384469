const homePermissions = [{ value: 0, label: "Can View Home" }];

const caseInsightsPermissions = [{ value: 0, label: "Can View Case Insights" }];

const financialInsights = [{ value: 0, label: "Can View Financial Insights" }];

export default [
  {
    value: "Home",
    label: "Home",
    permissions: homePermissions
  },
  {
    value: "Case Insights",
    label: "Case Insights",
    permissions: caseInsightsPermissions
  },
  {
    value: "Financial Insights",
    label: "Financial Insights",
    permissions: financialInsights
  }
];
