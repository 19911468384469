import { GET_BANKRUPTCY, BANKRUPTCY_LOADING } from '../actions/types';

const initialState = {
  bankruptcy: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BANKRUPTCY:
      return {
        bankruptcy: action.payload,
        loading: false
      };
    case BANKRUPTCY_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    default:
      return state;
  }
};
