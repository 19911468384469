import React, { useState, useEffect, Fragment } from 'react';
import queryString from 'query-string';
import Cryptr from 'cryptr';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button, Form } from 'reactstrap';

import CustomInput from '../../common/InputGroup';
import Loader from '../Loader';

import isEmpty from '../../utils/isEmpty';

const NewPassword = ({ history, ...props }) => {
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    passwordError: '',
    confirmPasswordError: '',
    token: null,
    isLoading: false,
    isChangedPassword: false
  });

  useEffect(() => {
    const tokenObj = queryString.parse(props.match.params.token);
    const cryptr = new Cryptr('reset-password-token');
    const token = Object.entries(tokenObj);
    const decriptedToken = cryptr.decrypt(token[0][0]).slice(6);
    setState({ ...state, token: decriptedToken });
  }, []);

  const onSubmitClick = async e => {
    e.preventDefault();
    if (state.password === state.confirmPassword) {
      try {
        setState({ ...state, isLoading: true });
        const data = {
          password: state.password,
          token: state.token
        };
        await axios.patch('/auth/reset-password', data);
        setState({ ...state, isLoading: false, isChangedPassword: true });
      } catch (error) {
        setState({
          ...state,
          passwordError: error.response.data.error,
          confirmPasswordError: error.response.data.error,
          isLoading: false
        });
      }
    } else {
      setState({
        ...state,
        passwordError: 'Password and Confirm Password must be the same.',
        confirmPasswordError: 'Password and Confirm Password must be the same.'
      });
    }
  };

  const onChange = e => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onGoToLoginClick = () => {
    history.push('/login');
  };

  let loader;
  if (state.isLoading) {
    loader = <Loader />;
  }

  return (
    <div
      style={{ width: '100vw', height: '100vh' }}
      className='d-flex align-items-center justify-content-center'
    >
      <Card className='p-4 card-style'>
        <CardBody>
          {!state.isChangedPassword ? (
            <Fragment>
              {loader}
              <Form onSubmit={onSubmitClick}>
                <h2>New Password</h2>
                <p className='text-muted'>
                  Please enter new password and confirm password.
                </p>
                <CustomInput
                  label='Password'
                  onChange={onChange}
                  name='password'
                  value={state.password}
                  type='password'
                  placeholder='Password'
                  autoComplete='password'
                  invalid={!isEmpty(state.passwordError)}
                  errorMess={
                    !isEmpty(state.passwordError) ? state.passwordError : null
                  }
                />
                <CustomInput
                  label='Confirm Password'
                  onChange={onChange}
                  name='confirmPassword'
                  value={state.confirmPassword}
                  type='password'
                  placeholder='Confirm Password'
                  autoComplete='confirmPassword'
                  invalid={!isEmpty(state.confirmPasswordError)}
                  errorMess={
                    !isEmpty(state.confirmPasswordError)
                      ? state.confirmPasswordError
                      : null
                  }
                />
              </Form>
              <Row className='mt-4'>
                <Col className=''>
                  <Button
                    className='px-4, gold-color-button'
                    onClick={onSubmitClick}
                    disabled={state.isLoading}
                  >
                    Submit
                  </Button>
                </Col>
                <Col className='text-right'>
                  <Button
                    color='link'
                    onClick={onGoToLoginClick}
                    className='px-0'
                  >
                    Go To Login
                  </Button>
                </Col>
              </Row>{' '}
            </Fragment>
          ) : (
            <Fragment>
              <h4>You have successfully changed your password. </h4>
              <Row>
                <Col className='d-flex'>
                  <Button
                    color='link'
                    style={{ color: '#ceac02' }}
                    onClick={onGoToLoginClick}
                    className='px-0'
                  >
                    Go To Login
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withRouter(NewPassword);
