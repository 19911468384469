import {
  SET_DEMAND_LETTER_LOADING,
  GET_DEMAND_LETTER,
  GET_ALL_DEMAND_LETTERS,
  GET_PREVIEW_DEMAND_LETTER,
  GET_DEMAND_LETTER_TEMPLATE,
  CLEAR_DEMAND_LETTER
} from '../actions/types';

const initialState = {
  demandLetter: {},
  demandLetters: [],
  loading: false,
  displayDemandLetter: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DEMAND_LETTER_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_DEMAND_LETTER:
      return {
        ...state,
        demandLetter: action.payload,
        loading: false,
        displayDemandLetter: true
      };
    case CLEAR_DEMAND_LETTER:
      return {
        ...state,
        demandLetter: action.payload,
        loading: false,
      };
    case GET_DEMAND_LETTER_TEMPLATE:
      let demandLetterData = action.payload;
      if (Object.keys(action.payload).length === 0) {
        demandLetterData = {
          placeholderParagraph1: '',
          placeholderParagraph2: '',
          placeholderParagraph3: '',
          placeholderParagraph4: '',
        }
      }
      return {
        ...state,
        demandLetter: demandLetterData,
        loading: false,
        displayDemandLetter: true
      };
    case GET_PREVIEW_DEMAND_LETTER:
      return {
        ...state,
        demandLetter: action.payload,
        loading: false,
        displayDemandLetter: true
      };
    case GET_ALL_DEMAND_LETTERS:
      return {
        ...state,
        demandLetters: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
