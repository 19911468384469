import { toast } from "react-toastify";

export const notificationSuccess = (text) => {
  return toast.success(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const notificationDanger = (text, time) => {
  return toast.error(text, {
    position: "top-right",
    autoClose: time ? time : 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const notificationWarning = (text) => {
  return toast.warn(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const notificationInfo = (text) => {
  return toast.info(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const notificationLegal = (text) => {
  return toast(text, {
    className: "gold-color-background",
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const realTimeNotification = (text) => {
  return toast.info(text, {
    position: "bottom-right",
    autoClose: 300000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};
