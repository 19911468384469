import {
  GET_ALL_NOTIFICATIONS,
  ADD_NOTIFICATION_REAL_TIME,
  MARK_NOTIFICATION
} from "../actions/types";

const initialState = {
  notifications: {}
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case ADD_NOTIFICATION_REAL_TIME: {
      const { payload } = action;
      if (payload.type === 0) {
        return {
          ...state,
          notifications: {
            ...state.notifications,
            litigation: [payload, ...state.notifications.litigation]
          }
        };
      } else if (payload.type === 1) {
        return {
          ...state,
          notifications: {
            ...state.notifications,
            transactional: [payload, ...state.notifications.transactional]
          }
        };
      } else if (payload.type === 2) {
        return {
          ...state,
          notifications: {
            ...state.notifications,
            corporate: [payload, ...state.notifications.corporate]
          }
        };
      }
    }
    case MARK_NOTIFICATION: {
      const { payload } = action;

      const newNotifications = { ...state.notifications };
      if (payload.type === 0) {
        const indexOfNotification = newNotifications.litigation.findIndex(
          noti => noti._id === payload._id
        );
        newNotifications.litigation[indexOfNotification].isRead = true;
      } else if (payload.type === 1) {
        const indexOfNotification = newNotifications.transactional.findIndex(
          noti => noti._id === payload._id
        );
        newNotifications.transactional[indexOfNotification].isRead = true;
      } else if (payload.type === 2) {
        const indexOfNotification = newNotifications.corporate.findIndex(
          noti => noti._id === payload._id
        );
        newNotifications.corporate[indexOfNotification].isRead = true;
      }
      return {
        ...state,
        notifications: newNotifications
      };
    }
    default:
      return state;
  }
};

export default notificationReducer;
