import {
  EXPENSES_LOADING,
  GET_EXPENSE,
  GET_ALL_EXPENSES,
  UPDATE_EXPENSE
} from '../actions/types';

const initialState = {
  expenses: [],
  expense: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EXPENSE:
      return {
        ...state,
        expense: action.payload,
        loading: false
      };
    case GET_EXPENSE:
      return {
        ...state,
        expense: action.payload,
        loading: false
      };
    case GET_ALL_EXPENSES:
      return {
        ...state,
        expenses: action.payload,
        loading: false
      };
    case EXPENSES_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    default:
      return state;
  }
}
