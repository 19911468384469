const tasksPermissions = [
  { value: 0, label: "Can View Add Task" },
  { value: 1, label: "Can View Task Table" }
];

const documentPermissions = [
  { value: 0, label: "Can View Add Document" },
  { value: 1, label: "Can View Document Table" }
];

export default [
  {
    value: "Tasks",
    label: "Tasks",
    permissions: tasksPermissions
  },
  {
    value: "Documents",
    label: "Documents",
    permissions: documentPermissions
  }
];
