import {
  SET_PARTIAL_PAYMENT,
  LOADING_PARTIAL_PAYMENT,
  GET_ALL_PARTIAL_PAYMENTS,
} from "./types";

const initialState = {
  partialPayment: {},
  allPartialPayments: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PARTIAL_PAYMENT:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_PARTIAL_PAYMENT:
      return {
        ...state,
        partialPayment: action.payload,
      };
    case GET_ALL_PARTIAL_PAYMENTS:
      return {
        ...state,
        allPartialPayments: action.payload,
      };
    default:
      return state;
  }
}
