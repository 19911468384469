const litigationReports = [
  { value: 0, label: "Active Cases" },
  { value: 1, label: "Bankruptcy Cases" },
  { value: 2, label: "Active Cases per Stage" },
  { value: 3, label: "Closed Cases" },
  { value: 4, label: "Case Value" },
  { value: 5, label: "Duration of Lawsuit" },
  { value: 6, label: "Court Costs" },
  { value: 7, label: "Payment Plan" },
  { value: 8, label: "Settlement And Payment" },
  { value: 9, label: "Financial Overview" },
  { value: 10, label: "Local Counsel Fees (Litigation)" }
];

const productivityReports = [{ value: 0, label: "Attorney Time" }];

const corporateReports = [
  { value: 0, label: "Corporate Costs" },
  { value: 1, label: "Misc Expenses" },
  { value: 2, label: "Local Counsel Fees (Non Litigation)" }
];

export default [
  {
    value: "Litigation Reports",
    label: "Litigation Reports",
    permissions: litigationReports
  },
  {
    value: "Productivity Reports",
    label: "Productivity Reports",
    permissions: productivityReports
  },
  {
    value: "Corporate Reports",
    label: "Corporate Reports",
    permissions: corporateReports
  }
];
