import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Cryptr from 'cryptr';
import axios from 'axios';
import queryString from 'query-string';
import { Button } from 'reactstrap';

import '../index.css';

class index extends Component {
  state = {
    isAccepted: 'false',
    isDone: false
  };
  async componentDidMount() {
    this.getData();
    await axios.patch(
      `/from-email/accept-decline-offer/${this.props.match.params.token}`
    );
  }

  getData = () => {
    const token = this.props.match.params.token;
    const cryptr = new Cryptr('offer-token');
    const data = queryString.parse(cryptr.decrypt(token));
    console.log(data);
    this.setState({
      isAccepted: data.accepted
    });
  };

  goBack = () => {
    window.close();
  };

  render() {
    return (
      <Fragment>
        <button className='btn-dark btn-sm mt-3 ml-3' onClick={this.goBack}>
          <i className='icons font-1xl d-inline cui-chevron-left'></i> Go Back
          To Email
        </button>
        <div className='d-flex align-items-start center-in-middle'>
          <div className='align-self-center mx-auto'>
            <h2>
              {' '}
              Thank you for{' '}
              {this.state.isAccepted === 'false' ? (
                <span className='gold-color'>rejecting</span>
              ) : (
                <span className='gold-color'>accepting</span>
              )}{' '}
              the offer
            </h2>
            <p className='mt-3'>
              You can login to legal app from{' '}
              <Link className='gold-color' to='/login'>
                here{' '}
              </Link>
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(index);
