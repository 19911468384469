import {
  GET_STATUS,
  UPDATE_STATUS_IDS,
  UPDATE_STATUS_IDS_LOCAL
} from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_STATUS:
      return { ...state, ...action.payload };
    case UPDATE_STATUS_IDS: {
      // const statusId = action.payload._id;
      return state;
    }
    case UPDATE_STATUS_IDS_LOCAL: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
