import { GET_ROLE, GET_ALL_ROLES, RESET_ROLE } from "../actions/types";

import { permissions } from "../../constants";

const initialState = { role: {}, roles: [] };

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ROLE: {
      console.log("OVDE SAM");
      return { ...state, role: action.payload };
    }
    case GET_ALL_ROLES: {
      return { ...state, roles: action.payload };
    }
    case RESET_ROLE: {
      return { ...state, role: action.payload };
    }
    default:
      return state;
  }
}
