import {
  GET_ALL_TASKS,
  TASKS_LOADING,
  GET_TASK,
  UPDATE_TASK,
  ADD_CLIENT_TO_TASK,
  GET_CURRENT_TASK_CLIENTS,
  GET_ALL_USERS_FOR_TASKS,
  ADD_ALL_CLIENTS_TO_TASK
} from '../actions/types';

const initialState = {
  tasks: {},
  task: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TASKS:
      return {
        ...state,
        tasks: action.payload,
        loading: false
      };
    case GET_TASK:
      return {
        ...state,
        task: action.payload,
        loading: false
      };
    case UPDATE_TASK:
      return {
        ...state,
        task: action.payload,
        loading: false
      };
    case TASKS_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_ALL_USERS_FOR_TASKS:
      return {
        ...state,
        task: {
          ...state.task,
          clients: action.payload
        }
      };
    case ADD_CLIENT_TO_TASK: {
      let newClinets = [...state.task.clients];
      const indexOfClient = newClinets.findIndex(
        client => client.client === action.payload.clientId
      );
      newClinets[indexOfClient][action.payload.property] =
        action.payload.isAssigned;
      return {
        ...state,
        task: {
          ...state.task,
          clients: newClinets
        }
      };
    }
    case ADD_ALL_CLIENTS_TO_TASK: {
      let newClinets = [...state.task.clients];
      if (action.payload) {
        newClinets.forEach(newClient => {
          newClient.isAssigned = true;
        });
      } else {
        newClinets.forEach(newClient => {
          newClient.isAssigned = false;
        });
      }
      return {
        ...state,
        task: {
          ...state.task,
          clients: newClinets
        }
      };
    }
    case GET_CURRENT_TASK_CLIENTS:
      return state;
    default:
      return state;
  }
}
