import {
  SET_TASK_NAMES_LOADING,
  GET_TASK_NAME,
  GET_TASK_NAMES,
  GET_ALL_USERS_FOR_TASK_NAMES,
  ADD_CLIENT_TO_TASK_NAME,
  GET_CURRENT_TASK_NAME_CLIENTS,
  ADD_ALL_CLIENTS_TO_TASK_NAME
} from '../actions/types';

const initialState = {
  taskName: {},
  taskNames: [],
  loading: false
};

const taskNamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASK_NAMES_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_TASK_NAME:
      return {
        ...state,
        taskName: action.payload,
        loading: false
      };
    case GET_TASK_NAMES:
      return {
        ...state,
        taskNames: action.payload,
        loading: false
      };
    case GET_ALL_USERS_FOR_TASK_NAMES:
      return {
        ...state,
        taskName: {
          ...state.taskName,
          staff: action.payload
        }
      };
    case ADD_CLIENT_TO_TASK_NAME: {
      let newStaff = [...state.taskName.staff];
      const indexOfStaff = newStaff.findIndex(
        staffItem => staffItem.user === action.payload.user
      );
      newStaff[indexOfStaff][action.payload.property] =
        action.payload.isAssigned;
      return {
        ...state,
        taskName: {
          ...state.taskName,
          staff: newStaff
        }
      };
    }
    case ADD_ALL_CLIENTS_TO_TASK_NAME: {
      let newStaff = [...state.taskName.staff];
      if (action.payload) {
        newStaff.forEach(staffItem => {
          staffItem.isAssigned = true;
        });
      } else {
        newStaff.forEach(staffItem => {
          staffItem.isAssigned = false;
        });
      }
      return {
        ...state,
        taskName: {
          ...state.taskName,
          staff: newStaff
        }
      };
    }
    case GET_CURRENT_TASK_NAME_CLIENTS:
      return state;
    default:
      return state;
  }
};

export default taskNamesReducer;
