import {
  GET_CASES,
  GET_CASE,
  ADD_COUNSEL_TO_CASEE,
  UPDATE_COUNSEL_FROM_CASEE,
  DELETE_COUNSEL_FROM_CASEE,
  ADD_PARTY_TO_CASEE,
  UPDATE_PARTY_FROM_CASEE,
  DELETE_PARTY_FROM_CASEE,
  CASE_LOADING,
  ADD_EXISTING_PARTY_TO_CASE,
  ADD_EXISTING_COUNSEL_TO_CASE,
  ADD_COMMENT,
  GET_ALL_COMMENTS,
  DELETE_COMMENT,
  UPDATE_COMMENT,
  GET_CASES_DATA_FOR_SELECT,
  GET_PLAINTIFFS_AND_DEFENDANTS,
  GET_CASES_FOR_ALL_TASKS,
  GET_DEFENDANTS,
  GET_CASES_FOR_ALL_PAST_TASKS
} from "../actions/types";

const initialState = {
  cases: [],
  comments: [],
  casee: {},
  dataForSelect: [],
  plaintiffsDefendants: [],
  casesForAllTasks: [],
  casesForPastTasks: [],
  defendants: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CASE_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_CASES_FOR_ALL_PAST_TASKS:
      return {
        ...state,
        casesForPastTasks: action.payload
      }
    case GET_CASES_DATA_FOR_SELECT:
      return {
        ...state,
        dataForSelect: action.payload
      };
    case GET_DEFENDANTS:
      return {
        ...state,
        defendants: action.payload
      };
    case GET_CASES_FOR_ALL_TASKS:
      return {
        ...state,
        casesForAllTasks: action.payload,
        loading: false
      };
    case GET_PLAINTIFFS_AND_DEFENDANTS:
      return {
        ...state,
        plaintiffsDefendants: action.payload
      };
    case GET_CASES:
      return {
        ...state,
        cases: action.payload,
        loading: false
      };
    case GET_CASE:
      return {
        ...state,
        casee: action.payload,
        loading: false
      };
    case ADD_COUNSEL_TO_CASEE:
      return {
        ...state,
        casee: action.payload
      };
    case UPDATE_COUNSEL_FROM_CASEE:
      return {
        ...state,
        casee: {
          ...state.casee,
          counsels: state.casee.counsels.map(counsel => {
            if (counsel._id === action.payload._id) {
              counsel = action.payload;
              return counsel;
            } else {
              return counsel;
            }
          })
        }
      };
    case DELETE_COUNSEL_FROM_CASEE:
      return {
        ...state,
        casee: action.payload,
        loading: false
      };
    case ADD_PARTY_TO_CASEE:
      return {
        ...state,
        casee: action.payload
      };
    case UPDATE_PARTY_FROM_CASEE:
      return {
        ...state,
        casee: {
          ...state.casee,
          parties: state.casee.parties.map(party => {
            if (party.party !== null) {
              if (party.party._id === action.payload._id) {
                return { ...party, party: action.payload };
              } else {
                return party;
              }
            } else {
              return party;
            }
          })
        }
      };
    case DELETE_PARTY_FROM_CASEE:
      return {
        ...state,
        casee: action.payload,
        loading: false
      };
    case ADD_EXISTING_PARTY_TO_CASE:
      return {
        ...state,
        casee: action.payload,
        loading: false
      };
    case ADD_EXISTING_COUNSEL_TO_CASE:
      return {
        ...state,
        casee: action.payload,
        loading: false
      };
    case ADD_COMMENT:
      return {
        ...state,
        casee: action.payload,
        loading: false
      };
    case GET_ALL_COMMENTS:
      return {
        ...state,
        comments: action.payload,
        loading: false
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false
      };
    case DELETE_COMMENT:
      return {
        ...state,
        comments: action.payload
      };
    default:
      return state;
  }
}
