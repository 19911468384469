import {
  GET_LOCATIONS,
  EVENTS_LOADING,
  GET_EVENT,
  UPDATE_EVENT,
  ADD_LOCATION_FROM_EVENT,
  GET_ALL_EVENTS,
  GET_LOCATIONS_WITH_EVENTS,
  ADD_CLIENT_TO_EVENT,
  GET_CURRENT_CLIENTS,
  GET_ALL_USERS_FOR_EVENTS,
  INVITE_PARTY_TO_EVENT,
  GET_PARTIES_FOR_EVENT,
  GET_ALL_PAST_EVENTS,
  GET_LOCATIONS_WITH_PAST_EVENTS
} from '../actions/types';

const initialState = {
  locations: [],
  locationsForPastEvents: [],
  events: [],
  pastEvents: {},
  event: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_LOCATIONS_WITH_PAST_EVENTS:
      return {
        ...state,
        locationsForPastEvents: action.payload
      }
    case GET_ALL_EVENTS:
      return {
        ...state,
        events: action.payload,
        loading: false
      };
    case GET_ALL_PAST_EVENTS:
      return {
        ...state,
        pastEvents: action.payload
      }
    case GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
        loading: false
      };
    case GET_LOCATIONS_WITH_EVENTS:
      return {
        ...state,
        locations: action.payload
      };
    case GET_EVENT:
      return {
        ...state,
        event: action.payload,
        loading: false
      };
    case UPDATE_EVENT:
      return {
        ...state,
        event: action.payload,
        loading: false
      };
    case ADD_LOCATION_FROM_EVENT: {
      return {
        ...state,
        locations: [...state.locations, action.payload]
      };
    }
    case GET_ALL_USERS_FOR_EVENTS: {
      return {
        ...state,
        event: {
          ...state.event,
          clients: action.payload
        }
      };
    }
    case ADD_CLIENT_TO_EVENT: {
      let newClinets = [...state.event.clients];
      const indexOfClient = newClinets.findIndex(
        client => client.client === action.payload.clientId
      );
      newClinets[indexOfClient][action.payload.property] =
        action.payload.isSharingAttending;
      return {
        ...state,
        event: {
          ...state.event,
          clients: newClinets
        }
      };
    }
    case INVITE_PARTY_TO_EVENT: {
      let newParties = [...state.event.parties];
      const indexOfParty = newParties.findIndex(
        party => party.partyId === action.payload.partyId
      );
      newParties[indexOfParty].isInvited = action.payload.isInvited;
      newParties[indexOfParty].caseId = action.payload.caseId;
      return {
        ...state,
        event: {
          ...state.event,
          parties: newParties
        }
      };
    }
    case GET_PARTIES_FOR_EVENT: {
      return {
        ...state,
        event: {
          ...state.event,
          parties: action.payload.data,
          case: action.payload.caseId
        }
      };
    }
    case GET_CURRENT_CLIENTS: {
      return state;
    }
    default:
      return state;
  }
};
