import React from "react";
import { Input, FormGroup, FormFeedback, Label } from "reactstrap";
import PropTypes from "prop-types";

export default function CustomInputGroup({
  onChange,
  onFocus,
  onBlur,
  name,
  label,
  required,
  value,
  type,
  placeholder,
  autoComplete,
  readOnly,
  invalid,
  errorMess,
  className,
  disabled,
  formClassName,
  min,
  step,
}) {
  return (
    <FormGroup className={formClassName}>
      {label ? <Label>{label}</Label> : null}
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        className={className}
        required={required}
        onChange={onChange}
        name={name}
        readOnly={readOnly}
        value={value}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        invalid={invalid}
        bsSize="sm"
        disabled={disabled}
        min={min}
        step={step}
      />
      <FormFeedback>{errorMess}</FormFeedback>
    </FormGroup>
  );
}

CustomInputGroup.propTypes = {
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  min: PropTypes.string,
  step: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  removeLabel: PropTypes.bool,
  autoComplete: PropTypes.string,
  errorMess: PropTypes.string,
  className: PropTypes.string,
};

CustomInputGroup.defaultProps = {
  readOnly: false,
  min: "0",
  step: "01",
  required: false,
};
