const openCasePermissions = [
  { value: 0, label: "Can View Cases Table" },
  { value: 1, label: "Can View Case" },
  { value: 2, label: "Can Add Cases" },
  { value: 3, label: "Can Update Case" },
  { value: 4, label: "Can Delete Case" },
  { value: 5, label: "Can Add Demand Letter" }
];

const closedCasePermissions = [
  { value: 0, label: "Can View Closed Cases Table" },
  { value: 1, label: "Can View Closed Case" },
  { value: 2, label: "Can Update Closed Case" },
  { value: 3, label: "Can Delete Case" },
  { value: 4, label: "Can Reopen Case" },
  { value: 5, label: "Can Add Demand Letter" }
];

const partyPermissions = [
  { value: 0, label: "Can View Parties Table" },
  { value: 1, label: "Can View Party" },
  { value: 2, label: "Can Add Party" },
  { value: 3, label: "Can Update Party" },
  { value: 4, label: "Can Delete Party" }
];

const counselPermissions = [
  { value: 0, label: "Can View Counsels Table" },
  { value: 1, label: "Can View Counsel" },
  { value: 2, label: "Can Add Counsel" }
];

const eventPermissions = [
  { value: 0, label: "Can View Events Table" },
  { value: 1, label: "Can View Event" },
  { value: 2, label: "Can Add Event" }
  // { value: 3, label: "Can Update Event" },
  // { value: 4, label: "Can Delete Event" }
];

const invoicesPermissions = [
  { value: 0, label: "Can View Invoices Table" },
  { value: 1, label: "Can View Invoice" },
  { value: 2, label: "Can Add Invoice" }
];

const taskPermissions = [
  { value: 0, label: "Can View Tasks Table" },
  { value: 1, label: "Can View Task" },
  { value: 2, label: "Can Add Task" },
  { value: 3, label: "Can Update Task" },
  { value: 4, label: "Can Delete Task" }
];

const DocumentPermissions = [
  { value: 0, label: "Can View Document Table" },
  // { value: 1, label: 'Can View Document' },
  { value: 2, label: "Can Add Document" },
  { value: 3, label: "Can Update Document" },
  { value: 4, label: "Can Delete Document" }
];

const expensesPermissions = [
  { value: 0, label: "Can View Expense Table" },
  { value: 1, label: "Can View Expense" },
  { value: 2, label: "Can Add Expense" },
  { value: 3, label: "Can Update Expense" },
  { value: 4, label: "Can Delete Expense" }
];

export default [
  {
    value: "Open Cases",
    label: "Open Cases",
    permissions: openCasePermissions
  },
  {
    value: "Closed Cases",
    label: "Closed Cases",
    permissions: closedCasePermissions
  },
  { value: "Parties", label: "Parties", permissions: partyPermissions },
  { value: "Counsels", label: "Counsels", permissions: counselPermissions },
  { value: "Invoices", label: "Invoices", permissions: invoicesPermissions },
  { value: "Events", label: "Events", permissions: eventPermissions },
  { value: "Tasks", label: "Tasks", permissions: taskPermissions },
  { value: "Documents", label: "Documents", permissions: DocumentPermissions },
  { value: "Expenses", label: "Expenses", permissions: expensesPermissions }
];
