const propertiesPermissions = [
  { value: 0, label: 'Can View Properties' },
  { value: 1, label: 'Can View Properties Table' },
  { value: 2, label: 'Can Add Properties' }
];

const leaseAbstractPermissions = [
  { value: 0, label: 'Can View Lease Abstract' },
  { value: 1, label: 'Can View Lease Abstract Table' },
  { value: 2, label: 'Can Add Lease Abstract' }
];

export default [
  {
    value: 'Properties',
    label: 'Properties',
    permissions: propertiesPermissions
  },
  {
    value: 'Lease Abstract',
    label: 'Lease Abstract',
    permissions: leaseAbstractPermissions
  }
];
