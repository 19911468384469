import { CLEAR_ERRORS } from './types';

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

export const clearAllErrors = () => dispatch => {
  return dispatch({
    type: CLEAR_ERRORS
  });
};
