import {
  ADD_COUNSEL,
  UPDATE_COUNSEL,
  DELETE_COUNSEL,
  GET_ALL_COUNSELS,
  GET_COUNSEL,
  COUNSEL_LOADING,
  GET_ALL_COUNSELS_NOT_IN_CASE,
  GET_COUNSELS_FOR_FILTERS
} from '../actions/types';

const initialState = {
  counsels: [],
  counselsNotInCase: [],
  counselsForFilters: [],
  counsel: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case COUNSEL_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_COUNSELS_FOR_FILTERS:
      return {
        ...state,
        counselsForFilters: action.payload
      };
    case GET_ALL_COUNSELS:
      return {
        ...state,
        counsels: action.payload,
        loading: false
      };
    case GET_ALL_COUNSELS_NOT_IN_CASE:
      return {
        ...state,
        counselsNotInCase: action.payload
      };
    case GET_COUNSEL:
      return {
        ...state,
        counsel: action.payload,
        loading: false
      };
    case ADD_COUNSEL:
      return {
        ...state,
        counsels: [...state.counsels, action.payload],
        loading: false
      };
    case UPDATE_COUNSEL:
      return {
        ...state,
        counsel: action.payload,
        loading: false
      };
    case DELETE_COUNSEL:
      return {
        ...state,
        counsel: {}
      };
    default:
      return state;
  }
}
