import { DashBoardPermission, DashboardChildren } from "./roles/Dashboard";
import { LitigationChildren, LitigationPermissions } from "./roles/Litigation";
import { AdministrationChildren } from "./roles/Administration";
import { CorporateChildren } from "./roles/Corporate";
import { TransactionalChildren } from "./roles/Transactional";
import { RealEstateChildren } from "./roles/RealEstate";
import { ReportsChildren } from "./roles/Reports";

export const calendarDateRangeOptions = [
  { value: 1, label: "View by day" },
  { value: 7, label: "View by week" },
  { value: 30, label: "View by month" },
];

export const optionsForActiveCloseCaseSelect = [
  { value: 1, label: "Active Cases" },
  { value: 2, label: "Closed Cases" },
  { value: 3, label: "Both" },
];

export const foldersForUpload = [
  { label: "All", value: 0, url: "/all" },
  { label: "Drafts", value: 1, url: "/drafts" },
  { label: "Discovery", value: 2, url: "/discovery" },
  { label: "Orders", value: 3, url: "/orders" },
  { label: "Pleadings", value: 4, url: "/pleadings" },
  { label: "Work Product", value: 5, url: "/work-product" },
  { label: "Correspondence", value: 6, url: "/correspondence" },
];

export const eventTypes = [
  { label: "Court", value: 0, name: "court" },
  { label: "Client Meeting", value: 1, name: "clientMeetings" },
  { label: "Consult", value: 2, name: "consults" },
  { label: "Travel", value: 3, name: "travels" },
  { label: "Staff Meeting", value: 4, name: "staffMeetings" },
  { label: "Other", value: 5, name: "other" },
];

export const priorities = [
  { label: "High", value: 0 },
  { label: "Middle", value: 1 },
  { label: "Low", value: 2 },
];

export const completionStatus = [
  { label: "Incomplete", value: 0 },
  { label: "Complete", value: 1 },
  { label: "Needs Review", value: 2 },
];

export const paymentMethods = [
  { label: "Debit", value: 0 },
  { label: "Credit", value: 1 },
  { label: "Check", value: 2 },
  { label: "ACH", value: 3 },
];

export const caseTypes = [
  { value: 0, label: "To be filed" },
  { value: 1, label: "New" },
  { value: 2, label: "Old" },
];

export const dateRangeOptions = [
  { label: "Last Week", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "Last Year", value: 3 },
];
export const notificationTypes = {
  litigation: {
    name: "Litigation",
    value: 0,
  },
  transactional: {
    name: "Transactional",
    value: 1,
  },
  corporate: {
    name: "Corporate",
    value: 2,
  },
};

export const supportTables = {
  paymentMethods: {
    name: "Payment Methods",
  },
  legalBusinessStructure: {
    name: "Legal Business Structure",
  },
  bankruptcyTypes: {
    name: "Bankruptcy Types",
  },
  fileLawsuitJuristiction: {
    name: "File Lawsuit Juristiction",
  },
  judgementStatus: {
    name: "Judgement Status",
  },
  branch: {
    name: "Branch",
  },
};

// New ROles

export const mainFunctionalities = [
  {
    value: "Dashboard",
    label: "Dashboard",
    permissions: DashBoardPermission,
    children: DashboardChildren,
  },
  {
    value: "Litigation",
    label: "Litigation",
    permissions: LitigationPermissions,
    children: LitigationChildren,
  },
  { value: "Calendar", label: "Calendar" },
  {
    value: "Transactional",
    label: "Transactional",
    permissions: [],
    children: TransactionalChildren,
  },
  {
    value: "Corporate",
    label: "Corporate",
    permissions: [],
    children: CorporateChildren,
  },
  {
    value: "Real Estate",
    label: "Real Estate",
    permissions: [],
    children: RealEstateChildren,
  },
  {
    value: "Administration",
    label: "Administration",
    children: AdministrationChildren,
    permissions: [],
  },
  {
    value: "Reports",
    label: "Reports",
    permissions: [],
    children: ReportsChildren,
  },
];

// Litigation

// End of New Roles

export const titleForCorporateOfficer = [
  { value: 1, label: "Officer" },
  { value: 2, label: "Manager/Director" },
  { value: 3, label: "Member/Shareholder" },
  { value: 4, label: "Secretary" },
];

export const feeDueOptions = [
  { label: "Yes", value: 0 },
  { label: "No", value: 1 },
];

export const functionalities = [
  { value: 0, label: "Cases" },
  { value: 1, label: "Parties" },
  { value: 2, label: "Counsels" },
  { value: 3, label: "Calendar" },
  { value: 4, label: "Documents" },
  { value: 5, label: "Settings" },
  { value: 6, label: "Events" },
  { value: 7, label: "Tasks" },
  { value: 8, label: "Expenses" },
  { value: 9, label: "Real Estate" },
  { value: 10, label: "Transactional" },
  { value: 11, label: "Corporate" },
  { value: 12, label: "Adobe Table" },
];

const casesPermissions = [
  { functionality: 0, value: 0, label: "Can View Cases Table" },
  { functionality: 0, value: 1, label: "Can View Case" },
  { functionality: 0, value: 2, label: "Can Add Cases" },
  { functionality: 0, value: 3, label: "Can Update Case" },
  { functionality: 0, value: 4, label: "Can Delete Case" },
  { functionality: 0, value: 5, label: "Can Add Demand Letter" },
];

const partiesPermissions = [
  { functionality: 1, value: 5, label: "Can View Parties Table" },
  { functionality: 1, value: 6, label: "Can View Party" },
  { functionality: 1, value: 7, label: "Can Add Parties" },
  { functionality: 1, value: 8, label: "Can Update Party" },
  { functionality: 1, value: 9, label: "Can Delete Party" },
];

export const permissions = [...casesPermissions, ...partiesPermissions];

export const states = [
  { value: 0, label: "AK" },
  { value: 1, label: "AL" },
  { value: 2, label: "AR" },
  { value: 3, label: "AS" },
  { value: 4, label: "AZ" },
  { value: 5, label: "CA" },
  { value: 6, label: "CO" },
  { value: 7, label: "CT" },
  { value: 8, label: "DC" },
  { value: 9, label: "DE" },
  { value: 10, label: "FL" },
  { value: 11, label: "GA" },
  { value: 12, label: "GU" },
  { value: 13, label: "HI" },
  { value: 14, label: "IA" },
  { value: 15, label: "ID" },
  { value: 16, label: "IL" },
  { value: 17, label: "IN" },
  { value: 18, label: "KS" },
  { value: 19, label: "KY" },
  { value: 20, label: "LA" },
  { value: 21, label: "MA" },
  { value: 22, label: "MD" },
  { value: 23, label: "ME" },
  { value: 24, label: "MI" },
  { value: 25, label: "MN" },
  { value: 26, label: "MO" },
  { value: 27, label: "MS" },
  { value: 28, label: "MT" },
  { value: 29, label: "NC" },
  { value: 30, label: "ND" },
  { value: 31, label: "NE" },
  { value: 32, label: "NH" },
  { value: 33, label: "NJ" },
  { value: 34, label: "NM" },
  { value: 35, label: "NV" },
  { value: 36, label: "NY" },
  { value: 37, label: "OH" },
  { value: 38, label: "OK" },
  { value: 39, label: "OR" },
  { value: 40, label: "PA" },
  { value: 41, label: "PR" },
  { value: 42, label: "RI" },
  { value: 43, label: "SC" },
  { value: 44, label: "SD" },
  { value: 45, label: "TN" },
  { value: 46, label: "TX" },
  { value: 47, label: "UT" },
  { value: 48, label: "VA" },
  { value: 49, label: "VI" },
  { value: 50, label: "VT" },
  { value: 51, label: "WA" },
  { value: 52, label: "WI" },
  { value: 53, label: "WV" },
  { value: 55, label: "WY" },
];

export const compassDivisions = [
  { value: "equipmentFinancing", label: "Compass Equipment Finance, LLC" },
  { value: "funding", label: "Compass Funding Solutions, LLC" },
  { value: "insurance", label: "Compass Insurance Group, Inc" },
  { value: "trailerLeasing", label: "Compass Lease, LLC" },
  { value: "logistics", label: "Compass Logistics, LLC" },
  { value: "paymentServices", label: "Compass Payment Services, LLC" },
  { value: "realEstate", label: "Compass Real Estate Holding, LLC" },
  {
    value: "specialtyInsurance",
    label: "Compass Specialty Insurance RRG, Inc",
  },
  { value: "truckLeasing", label: "Compass Truck Rental & Leasing, LLC" },
  { value: "truckSales", label: "Compass Truck Sales, LLC" },
  { value: "truckingAssociation", label: "International Trucking Association" },
  { value: "smartBoard", label: "SmartBoard, LLC" },
];

export const litigationNonlitigation = [
  { value: 1, label: "Litigation" },
  { value: 2, label: "Non Litigation" },
];

export const tenantOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const partyTypes = [
  { value: 1, label: "Individual" },
  { value: 2, label: "Business" },
];

export const caseStages = [
  { value: 1, label: "New" },
  { value: 2, label: "Pre-Litigation" },
  { value: 9, label: "Delta" },
  { value: 3, label: "Litigation" },
  { value: 10, label: "Payment Plan" },
  { value: 4, label: "Send To Local Counsel" },
  { value: 5, label: "In Bankruptcy/On Court Bankruptcy Roll" },
  { value: 6, label: "On Hold" },
  { value: 7, label: "Post Litigation" },
  { value: 8, label: "Closed" },
];

export const counselType = [
  { value: 1, label: "Counsel" },
  { value: 2, label: "Judge" },
];

export const paymentTypes = [
  { value: 1, label: "Weekly" },
  { value: 2, label: "Monthly" },
];

export const adobeReminderOptions = [
  { value: "DAILY_UNTIL_SIGNED", label: "Daily" },
  { value: "WEEKDAILY_UNTIL_SIGNED", label: "Week daily " },
  { value: "EVERY_OTHER_DAY_UNTIL_SIGNED", label: "Every other day" },
  { value: "EVERY_THIRD_DAY_UNTIL_SIGNED", label: "Every third day" },
  { value: "EVERY_FIFTH_DAY_UNTIL_SIGNED", label: "Every fifth day" },
  { value: "WEEKLY_UNTIL_SIGNED", label: "Weekly" },
];

export const bankruptcyStatus = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Dismissed" },
  { value: 3, label: "Discharged" },
];

export const reminderTypes = [
  { value: 23, label: "No Reminder" },
  { value: 0, label: "15 min" },
  { value: 1, label: "30 min" },
  { value: 2, label: "45 min" },
  { value: 3, label: "1 h" },
  { value: 4, label: "2 h" },
  { value: 5, label: "3 h" },
  { value: 6, label: "4 h" },
  { value: 7, label: "5 h" },
  { value: 8, label: "6 h" },
  { value: 9, label: "7 h" },
  { value: 10, label: "8 h" },
  { value: 11, label: "9 h" },
  { value: 12, label: "10 h" },
  { value: 13, label: "11 h" },
  { value: 14, label: "12 h" },
  { value: 15, label: "1 day" },
  { value: 16, label: "2 days" },
  { value: 17, label: "3 days" },
  { value: 18, label: "4 days" },
  { value: 19, label: "1 week" },
  { value: 20, label: "2 weeks" },
  { value: 21, label: "3 weeks" },
  { value: 22, label: "1 month" },
];
