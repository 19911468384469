export const PREVIEW_PAYMENT_PLAN = "PREVIEW_PAYMENT_PLAN";
export const PAYMENT_PLAN_LOADING = "PAYMENT_PLAN_LOADING";
export const GET_PAYMENT_PLAN = "GET_PAYMENT_PLAN";
export const DISPLAY_CREATE_PLAN = "DISPLAY_CREATE_PLAN";
export const SET_PAYMENT_PLAN = "SET_PAYMENT_PLAN";
export const DISPLAY_PAYMENT_PLAN = "DISPLAY_PAYMENT_PLAN";
export const CREATE_PAYMENT_PLAN = "CREATE_PAYMENT_PLAN";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const UPDATE_PAYMENT_PLAN = "UPDATE_PAYMENT_PLAN";
export const CONFIRM_FULL_PAYMENT = "CONFIRM_FULL_PAYMENT";
