import {
  PROPERTY_LOADING,
  GET_ALL_PROPERTIES,
  GET_PROPERTY
} from '../actions/types';

const initialState = {
  property: {},
  properties: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROPERTY_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_ALL_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        loading: false
      };
    case GET_PROPERTY:
      return {
        ...state,
        property: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
