import {
  GET_MISC_EXPENSES,
  SET_MISC_EXPENSES_LOADING,
  GET_ALL_MISC_EXPENSES
} from './types';

const initialState = {
  miscExpense: {},
  miscExpenses: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MISC_EXPENSES_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_MISC_EXPENSES:
      return {
        ...state,
        miscExpense: action.payload,
        loading: false
      };
    case GET_ALL_MISC_EXPENSES:
      return {
        ...state,
        miscExpenses: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
