import {
  LEASE_ABSTRACT_LOADING,
  GET_ALL_LEASE_ABSTRACT,
  GET_LEASE_ABSTRACT
} from '../actions/types';

const initialState = {
  leaseAbstract: {},
  leaseAbstracts: [],
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LEASE_ABSTRACT_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_ALL_LEASE_ABSTRACT:
      return {
        ...state,
        leaseAbstracts: action.payload,
        loading: false
      };
    case GET_LEASE_ABSTRACT:
      return {
        ...state,
        leaseAbstract: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
