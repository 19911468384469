import {
  GET_SUPPORT_TABLES,
  GET_SUPPORT_TABLE,
  POST_SUPPORT_TABLE,
  PATCH_SUPPORT_TABLE,
  RESET_SUPPORT_TABLE
} from '../actions/types';

const initialState = {
  supportTables: [],
  supportTable: {},
  loading: false,
  isUpdated: false
};

const supportTablesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUPPORT_TABLES:
      return {
        ...state,
        supportTables: payload
      };
    case GET_SUPPORT_TABLE:
      return {
        ...state,
        supportTable: payload
      };
    case PATCH_SUPPORT_TABLE:
      return {
        ...state,
        supportTable: payload,
        isUpdated: true
      };
    case RESET_SUPPORT_TABLE:
      return {
        ...state,
        supportTable: payload,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default supportTablesReducer;
