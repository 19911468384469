import axios from "axios";

import {
  GET_ALL_USERS_ASSIGNED_TO_EXPENSES,
  GET_USERS_TASK_ARE_ASSIGNED_TO,
  GET_USERS_TRANSACTIONAL_TASK_ARE_ASSIGNED_TO,
  GET_USER,
  GET_ALL_USERS,
  UPDATE_USER,
  RESET_USER,
  CREATE_USER,
  GET_ERRORS,
  GET_ALL_USERS_FOR_SELECT
} from "./types";
import {
  notificationSuccess,
  notificationDanger
} from "../utils/customToastify";

export const getUser = userId => async dispatch => {
  try {
    const response = await axios.get(`/users/${userId}`);
    dispatch({
      type: GET_USER,
      payload: response.data
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const changePassword = (userId, data) => async dispatch => {
  try {
    const response = await axios.patch(
      `/users/${userId}/change-password`,
      data
    );

    if (response.status === 200) {
      notificationSuccess("You successfuly changed password");
    }
  } catch (error) {
    if (error.response.status === 400) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      return "displayError";
    }
  }
};

export const getAllUsersAssignedToExpenses = () => async dispatch => {
  try {
    const response = await axios.get("/users/with-expenses");
    dispatch({
      type: GET_ALL_USERS_ASSIGNED_TO_EXPENSES,
      payload: response.data
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getUsersTaskAreAssignedTo = () => async dispatch => {
  try {
    const response = await axios.get("/users/task-are-assigned-to");
    dispatch({
      type: GET_USERS_TASK_ARE_ASSIGNED_TO,
      payload: response.data
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getUsersTransactionalTaskAreAssignedTo = () => async dispatch => {
  try {
    const response = await axios.get(
      "/users/transactional-task-are-assigned-to"
    );
    dispatch({
      type: GET_USERS_TRANSACTIONAL_TASK_ARE_ASSIGNED_TO,
      payload: response.data
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllUsersForSelect = () => async dispatch => {
  try {
    const response = await axios.get("/users/for-select");
    if (response.status === 200) {
      dispatch({
        type: GET_ALL_USERS_FOR_SELECT,
        payload: response.data
      });
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getUsersAssignedToDocuments = caseId => async dispatch => {
  try {
    const response = await axios.get("/users/for-documents", {
      params: { caseId }
    });
    if (response.status === 200) {
      dispatch({
        type: GET_ALL_USERS_FOR_SELECT,
        payload: response.data
      });
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllUsers = filters => async dispatch => {
  try {
    const response = await axios.get("/users", { params: { ...filters } });
    dispatch({
      type: GET_ALL_USERS,
      payload: response.data
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUser = (userId, data) => async dispatch => {
  try {
    const response = await axios.patch(`/users/${userId}`, data);
    dispatch({
      type: UPDATE_USER,
      payload: response.data
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const createUser = data => async dispatch => {
  try {
    const response = await axios.post(`/users`, data);
    dispatch({
      type: CREATE_USER,
      payload: response.data
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const resetUser = () => async dispatch => {
  try {
    dispatch({
      type: RESET_USER,
      payload: {}
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getUsersClients = userId => async dispatch => {
  try {
    const response = await axios.get(`/users/${userId}/clients`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteUser = userId => async dispatch => {
  try {
    const response = await axios.delete(`/users/delete/${userId}`);

    if (response.status === 200) {
      notificationDanger("You just deleted user");
      // dispatch({
      //   type: GET_ALL_USERS,
      //   payload: response.data
      // });
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getUsersFromOneCase = caseId => async dispatch => {
  try {
    const response = await axios.get(`/users/${caseId}/case-users`);
    if (response.status === 200) {
      dispatch({
        type: GET_ALL_USERS_FOR_SELECT,
        payload: response.data
      });
    }
  } catch (error) {
    throw new Error(error);
  }
};
