import {
  SET_TRANSACTIONAL_TASK_LOADING,
  GET_TRANSACTIONAL_TASK,
  GET_ALL_TRANSACTIONAL_TASKS,
  GET_ALL_USERS_FOR_TRANSACTIONAL_TASKS,
  ADD_CLIENT_TO_TRANSACTIONAL_TASK,
  GET_CURRENT_TRANSACTIONAL_TASK_CLIENTS,
  ADD_ALL_CLIENTS_TO_TRANSACTIONAL_TASK
} from '../actions/types';

const initialState = {
  transactionalTask: {},
  transactionalTasks: [],
  loading: false
};

const transactionalTasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTIONAL_TASK_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_TRANSACTIONAL_TASK:
      return {
        ...state,
        loading: false,
        transactionalTask: action.payload
      };
    case GET_ALL_TRANSACTIONAL_TASKS:
      return {
        ...state,
        loading: false,
        transactionalTasks: action.payload
      };
    case GET_ALL_USERS_FOR_TRANSACTIONAL_TASKS:
      return {
        ...state,
        transactionalTask: {
          ...state.transactionalTask,
          staff: action.payload
        }
      };
    case ADD_CLIENT_TO_TRANSACTIONAL_TASK: {
      let newStaff = [...state.transactionalTask.staff];
      const indexOfStaff = newStaff.findIndex(
        staffItem => staffItem.user === action.payload.user
      );
      newStaff[indexOfStaff][action.payload.property] =
        action.payload.isAssigned;
      console.log(newStaff);
      return {
        ...state,
        transactionalTask: {
          ...state.transactionalTask,
          staff: newStaff
        }
      };
    }
    case ADD_ALL_CLIENTS_TO_TRANSACTIONAL_TASK: {
      let newStaff = [...state.transactionalTask.staff];
      if (action.payload) {
        newStaff.forEach(staffItem => {
          staffItem.isAssigned = true;
        });
      } else {
        newStaff.forEach(staffItem => {
          staffItem.isAssigned = false;
        });
      }
      return {
        ...state,
        transactionalTask: {
          ...state.transactionalTask,
          staff: newStaff
        }
      };
    }
    case GET_CURRENT_TRANSACTIONAL_TASK_CLIENTS:
      return state;
    default:
      return state;
  }
};

export default transactionalTasksReducer;
