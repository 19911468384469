import { UPDATE_PAYMENT_PLAN, CREATE_PAYMENT_PLAN, PREVIEW_PAYMENT_PLAN, PAYMENT_PLAN_LOADING, DISPLAY_PAYMENT_PLAN, GET_PAYMENT_PLAN, DISPLAY_CREATE_PLAN, SET_PAYMENT_PLAN } from './types'

const initialState = {
  paymentPlan: {},
  paymentPlans: [],
  loading: false,
  displayCreatePlan: true,
  displayPlan: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PAYMENT_PLAN_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case DISPLAY_CREATE_PLAN:
      return {
        ...state,
        displayCreatePlan: action.payload,
        displayPlan: false,
      };
    case DISPLAY_PAYMENT_PLAN:
      return {
        ...state,
        displayPlan: action.payload
      };
    case PREVIEW_PAYMENT_PLAN:
      return {
        ...state,
        displayCreatePlan: true,
        displayPlan: true,
        paymentPlan: action.payload
      };
    case SET_PAYMENT_PLAN: {
      return {
        ...state,
        paymentPlan: action.payload,
        loading: false
      }
    }
    case UPDATE_PAYMENT_PLAN:
      return {
        ...state,
        displayCreatePlan: false,
        displayPlan: true,
        paymentPlan: action.payload
      };
    default:
      return state;
  }
}
