const userPermissions = [
  { value: 0, label: 'Can View User' },
  { value: 1, label: 'Can View User Table' },
  { value: 2, label: 'Can Add User' }
];

const rolesPermissions = [
  { value: 0, label: 'Can View Role' },
  { value: 1, label: 'Can View Role Table' },
  { value: 2, label: 'Can Add Role' }
];

const supportTablePermissions = [
  { value: 0, label: 'Can View Support Table' },
  { value: 1, label: 'Can View Support Table Table' },
  { value: 2, label: 'Can Add Support Table' }
];

const activitiesPermissions = [
  { value: 0, label: 'Can View Activities' },
  { value: 1, label: 'Can View Activities Table' },
  { value: 2, label: 'Can Add Activities' }
];

const createTaskNamePermissions = [
  { value: 0, label: 'Can View Task Name' },
  { value: 1, label: 'Can View Task Name Table' },
  { value: 2, label: 'Can Add Task Name' }
];

const locationsPermissions = [
  { value: 0, label: 'Can View Location' },
  { value: 1, label: 'Can View Location Table' },
  { value: 2, label: 'Can Add Location' }
];

export default [
  {
    value: 'Users',
    label: 'Users',
    permissions: userPermissions
  },
  {
    value: 'Roles',
    label: 'Roles',
    permissions: rolesPermissions
  },
  {
    value: 'Support Tables',
    label: 'Support Tables',
    permissions: supportTablePermissions
  },
  {
    value: 'Activities (Expenses)',
    label: 'Activities (Expenses)',
    permissions: activitiesPermissions
  },
  {
    value: 'Create Task - Transactional',
    label: 'Create Task - Transactional',
    permissions: createTaskNamePermissions
  },
  {
    value: 'Locations (Events)',
    label: 'Locations (Events)',
    permissions: locationsPermissions
  }
];
