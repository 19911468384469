import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Row, Col, Card, CardBody, Form, Button, Alert } from 'reactstrap';

import CustomInput from '../../common/InputGroup';
import Loader from '../Loader';
import isEmpty from '../../utils/isEmpty';
import './index.css';

const EnterEmail = ({ history }) => {
  const [state, setState] = useState({
    email: '',
    emailError: '',
    showAlert: false,
    isLoading: false
  });

  useEffect(() => {
    if (state.showAlert === true) {
      setTimeout(() => {
        setState({ ...state, showAlert: false });
      }, 8000);
    }
  }, [state.showAlert]);

  const onSubmitClick = async e => {
    e.preventDefault();
    try {
      setState({ ...state, isLoading: true });
      const data = { email: state.email };
      await axios.patch('/auth/forgot-password', data);

      setState({ ...state, showAlert: true, isLoading: false });
    } catch (error) {
      setState({
        ...state,
        emailError: error.response.data.error,
        isLoading: false
      });
    }
  };

  const onChange = e => {
    const { value } = e.target;
    setState({ ...state, email: value });
  };

  const onGoBackClick = () => {
    history.push('/login');
  };

  let alert;
  if (state.showAlert) {
    alert = (
      <Alert color='success'>
        If a Legal account exists for {state.email}, an e-mail will be sent with
        further instructions.
      </Alert>
    );
  }

  let loader;
  if (state.isLoading) {
    loader = <Loader />;
  }

  return (
    <div
      style={{ width: '100vw', height: '100vh' }}
      className='d-flex align-items-center justify-content-center'
    >
      <Card className='p-4 card-style'>
        {alert}
        <CardBody>
          {loader}
          <Form onSubmit={onSubmitClick}>
            <h2>Forgot Password</h2>
            <p className='text-muted'>
              Please enter your email address to reset your password.
            </p>
            <CustomInput
              label='Email'
              onChange={onChange}
              name='email'
              value={state.email}
              type='text'
              placeholder='Email'
              autoComplete='email'
              invalid={!isEmpty(state.emailError)}
              errorMess={!isEmpty(state.emailError) ? state.emailError : null}
            />
          </Form>
          <Row className='mt-4'>
            <Col className=''>
              <Button
                className='px-4 gold-color-button'
                onClick={onSubmitClick}
                disabled={state.isLoading}
              >
                Submit
              </Button>
            </Col>
            <Col className='text-right'>
              <Button color='link' onClick={onGoBackClick} className='px-0'>
                Go Back To Login
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default withRouter(EnterEmail);
